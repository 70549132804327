<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div v-if="!succesSend">
            <div class="titre">Motif du refus</div>
            <div class="sous-titre mt-3">Veuillez nous expliquer la raison du refus svp</div>

            <textarea name="" id="" v-model="motif" class="w-full h-full textarea mt-4"/>

            <div class="">
              <div class="button continuer mt-6 w-full" @click="send">Envoyer</div>
            </div>
          </div>

          <div v-if="succesSend" class="pt-10 pb-10">
            <div class="flex justify-center">
              <svg-icon
                name="valid-green"
                class="h-20 cursor-pointer"
                original
              />
            </div>

            <div class="titre text-center mt-5">Merci pour votre retour.</div>
            <div class="sous mt-5 text-center w-4/5 mr-auto ml-auto">Nos équipe vous revienne dans les plus brefs délais si besoin.</div>

            <div class="flex justify-center mt-6">
              <div class="button w-4/6" @click="goTo('sinistre')">
               OK
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      motif: '',
      succesSend: false
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    send () {
      this.donnes.body.rejectMessage = this.motif
      http.patch(apiroutes.baseURL + apiroutes.sinister + '/' + this.donnes.id, {
        body: this.donnes.body,
        agreement: 'REJECT'
      })
        .then(response => {
          this.succesSend = true
          console.log('successss images')
          console.log(response)
        })
        .catch(error => {
          console.log('erreur images')
          console.log(error)
        })
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    goTo (index) {
      this.$router.push('/' + index)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 50%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre {
      font-weight: bold;
      font-size: 28px;
      line-height: 160%;
      color: #000000;
    }
    .sous-titre {
      font-weight: normal;
      font-size: 16px;
      line-height: 160%;
      color: #505050;
    }
    .sous{
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      color: #000000;
    }
    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }
    .textarea {
      width: 100%;
      background: #F1F1F1;
      border-radius: 5px;
      height: 285px;
      font-size: 18px;
      border: 1px solid #F1F1F1;
      box-sizing: border-box;
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 700px){
      .success-container{
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        padding: 50px 30px !important;
        text-align: center;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 17px;
      }

      .button{
        width: 100%;
        height: 50px;
        font-size: 17px;
      }
      .textarea {
        height: 170px;
        font-size: 18px;
      }
    }

    @media screen and (max-width: 325px){

    }
</style>
