<template>
  <div class="about-page w-full h-screen">
    <motif-refus :want-to-make-offer="activMotif" :donnes="donnes" @oga="retourMotif" />
    <type-payment :want-to-make-offer="activPaye" :donnes="donnes" @oga="retourPaye" />
    <div class="header-back">
      <header-back steep="proposition"/>
    </div>

    <div class="pt-6">
      <div class="w-full bloc1 md:mt-6">
        <div class="boite flex items-center mb-4">
          <div class="text-center w-full">
            <div class="sous-titre">Montant TTC</div>
            <div class="titre mt-5">{{ donnes.amount.toLocaleString() }} FCFA</div>
          </div>
        </div>

        <div class="button w-full mt-5" @click=" activPaye = true">Acceper la proposition</div>
        <div class="button w-full non mt-4" @click="activMotif = true">Refuser</div>
      </div>
    </div>

  </div>
</template>

<script>
import headerBack from '@/components/helper/headerBack'
import motifRefus from '@/components/popup/motifRefus'
import typePayment from '@/components/popup/typePayment'

export default {
  name: 'index',
  components: {
    headerBack,
    motifRefus,
    typePayment
  },

  data () {
    return {
      activMotif: false,
      activPaye: false,
      donnes: null
    }
  },

  mounted () {
    this.donnes = this.$store.getters.sinistre
    console.log(this.donnes)
  },

  methods: {
    retourMotif (answer) {
      this.activMotif = answer
    },

    retourPaye (answer) {
      this.activPaye = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 100%;
  font-family: $font-default;
  height: 100vh;
}

.header-back{
  margin-top: -60px;
}

.bloc1{
  width: 42%;
  margin-right: auto;
  margin-left: auto;
}

.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 27px;
  color: #000000;
}
.sous-titre{
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #909090;
}
.boite{
  height: 220px;
  background: #E8E8E8;
  border-radius: 15px;
}

.button{
  height: 60px;
  background: #0269AD;
  border: 1px solid #0269AD;
  box-sizing: border-box;
  border-radius: 10px;
  color: white;
  font-weight: normal;
  font-size: 20px;
  line-height: 153%;
}
.non{
  color: #FF4B55;
  background-color: #F6E4E5;
  border: none;
}
@media screen and (max-width: 1024px) and (min-width: 770px){

}

@media screen and (max-width: 769px) and (min-width: 701px){

}

@media screen and (max-width: 700px){

  .bloc1{
    width: 90%;
  }
  .about-page{
    padding-bottom: 40px;
  }
  .button{
    height: 50px;
    font-size: 18px;
  }
}

@media screen and (max-width: 325px){

}
</style>
