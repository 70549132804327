<template>
  <div class="app-header flex items-center">
    <div class="web-header w-full flex items-center">
      <div class="button md:mr-6 mr-4" @click="goBack">
        <svg-icon
          name="arrow"
          class="md:h-4 h-12  cursor-pointer md:mr-4"
          original
        />
       <div class="mr-2 retour">Retour</div>
      </div>

      <div class="titre" v-if="steep !=='afterAnswer' && steep !=='proposition'">
        Devis
      </div>

      <div v-if="steep === 'afterAnswer'">
        <div class="sous-titre">
         {{label}}
        </div>

        <div class="titre md:mt-1">
          Complèter vos informations
        </div>
      </div>

      <div class="titre" v-if="steep ==='proposition'">
        Proposition financière
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TheHeader',
  components: {
  },
  props: {
    steep: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      product: null,
      label: ''
    }
  },

  mounted () {
    this.product = this.$store.getters.trackFirstDevis
    if (this.product !== null) {
      if (this.product.type === 'Auto') {
        this.label = this.product.marque + ' ' + this.product.model + ' | ' + this.product.plaque
      }
    }
  },

  methods: {
    goBack () {
      if (this.steep === 'proposition') {
        this.$router.push('sinistre')
      }
      this.$emit('info', true)
    },
    accueil () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.app-header {
  width: 100%;
  background: linear-gradient(84.41deg, #49BF7C -58.44%, #0269AD 108.79%);
  z-index: 9;
  height: 132px;
  color: #000000;
  padding: 0px 300px 0px 250px;
  font-family: $font-default;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 34px;
  color: #FFFFFF;
}
.sous-titre{
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
  opacity: 0.5;
}
.button {
  cursor: pointer;
  background-color: $white;
  width: 192px;
  height: 60px;
  font-family: 'Rubik', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  border-radius: 15px;
  border: none;
  color: black;
}
.button:hover{
  background-color: #ebedee;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .app-header {
    padding: 0px 30px 0px 170px;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px){
  .app-header {
    padding: 0px 20px 0px 20px;
  }
}

@media only screen and (max-width: 700px) {
  .app-header {
    padding: 0px 10px 0px 20px;
    height: 91px;
  }
  .retour{
    display: none;
  }
  .button{
    padding: 15px;
    width: 45px;
    height: 45px;
    border-radius: 11.25px;
  }
  .titre{
    font-size: 26px;
  }
    .sous-titre{
      font-size: 14px;
    }
    .titre{
      font-size: 18px;
    }
}
@media screen and (max-width: 325px){
  .sous-titre{
    font-size: 12px;
  }
  .titre{
    font-size: 14px;
  }
  .app-header {
    padding: 0px 10px 0px 10px;
  }
}
</style>
